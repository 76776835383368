.page {
    position: relative;
    top: 100px;
    bottom: 50px;
    background-color: black;
}

.video {
    max-height: 800px;
    position: absolute;
    top: -100px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.main-hero {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    h1 {
        width: 900px;
        font-size: 5rem;
        font-weight: 700;
        color: $light;
        text-align: center;
        z-index: 2;
    }

    p {
        font-size: 1.5rem;
        font-weight: 500;
        color: $dark;
        border: 1px solid $light;
        padding: 2rem;
        border-radius: 10px;
        margin-top: 2rem;
        z-index: 2;
    }

    .online-registration-button {
        margin-top: 15%;
        padding: 12px 24px;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        background-color: #007BFF;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease;
        z-index: 2;

        &:hover {
            background-color: #0056b3;
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }

        &:active {
            transform: translateY(0);
        }
    }

    .hero-images {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        z-index: 1;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.05);
            }
        }
    }
}

.hero {
    @include flexColumn;
    padding: 2rem;
    color: $light;

    h2 {
        font-size: 4rem;
        font-weight: 700;
        color: $light;
    }

    p {
        font-size: 1.5rem;
        font-weight: 500;
        color: $light;
        text-align: justify;
    }
}

.b {
    background: $dark;
    color: $light;
}

.ba {
    background: $dark-active;
    color: $light;
}

.w {
    background: $light;
    color: $dark;

    h2 {
        color: $dark;
    }

    p {
        color: $dark;
    }
}

@keyframes moveLocation {
    0% {
        transform: translate(-50%, 75%);
        opacity: 0;
    }

    25% {
        transform: translate(-50%, 50%);
        opacity: 1;
    }

    50% {
        transform: translate(-50%, 0%);
        opacity: 1;
    }

    75% {
        transform: translate(-50%, -50%);
        opacity: 0;
    }

    100% {
        transform: translate(-50%, -100%);
        opacity: 0;
    }
}

.location-animation {
    animation: moveLocation 12s ease-in-out infinite;
}

.hero-image {
    width: 100%;
    height: 100%;
};