@import 'utils';

*::before, *::after, * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    font-family: $os;
    background-color: $light;
    color: $dark;
    overflow-x: hidden;
}

.app {
    @include grid1;
}

@import 'Header';
@import 'Page';
@import 'Footer';
@import 'Tablet';
@import 'Mobile';

