@media screen and (max-width: 912px) {
    .main-hero {
        height: 700px;
        
        .hero-images {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }

        h1 {
            font-size: 3rem;
            width: 85%;
            top: 15%;
            left: 7.5%;
        }

        p {
            font-size: 1.1rem;
            width: 85%;
        }

        .online-registration-button {
            margin-top: 12%;
            padding: 10px 20px;
            font-size: 16px;
        }
    }

    .navigation {
        display: none;
    }

    .hero {
        padding: 1.5rem;
        h2 {
            font-size: 2.5rem;
        }

        div {
            max-width: 100%;
            padding: 20px;
        }
    }

    .gallery {
        padding: 15px;
        
        h2 {
            font-size: 2.2rem;
            margin-bottom: 20px;
        }

        div {
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            padding: 15px;
        }

        img {
            height: 280px;
        }
    }

    article {
        margin: 15px;
        padding: 20px;

        h2 {
            font-size: 2.2rem;
            margin-bottom: 20px;
        }

        h3 {
            font-size: 1.4rem;
            margin-bottom: 10px;
        }

        p {
            font-size: 1rem;
        }

        form {
            gap: 12px;
        }

        input, textarea {
            padding: 10px;
            font-size: 16px;
        }

        button {
            padding: 10px 20px;
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 540px) {
    .main-hero {
        height: 600px;
        
        .hero-images {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(4, 1fr);
        }

        h1 {
            font-size: 2rem;
            width: 90%;
            top: 10%;
            left: 5%;
            transform: none;
        }

        p {
            font-size: 1rem;
            width: 90%;
        }

        .online-registration-button {
            margin-top: 10%;
            padding: 8px 16px;
            font-size: 14px;
        }
    }

    .navigation {
        display: none;
    }

    .gallery {
        div {
            grid-template-columns: 1fr;
        }
    }
}
