@media screen and (max-width: 430px) {
    .logo {
        width: 1rem;
    }

    .header {
        width: 100vw;
    }

    .video {
        width: 100vw;
    }

    .main-hero {
        height: 700px;
        
        .hero-images {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(4, 1fr);
        }

        h1 {
            font-size: 1.4rem;
            width: 90%;
            top: 10%;
            left: 5%;
            transform: none;
        }

        p {
            font-size: 1rem;
            width: 90%;
        }

        .online-registration-button {
            margin-top: 10%;
            padding: 8px 16px;
            font-size: 14px;
        }
    }

    .navigation {
        display: none;
    }

    .hero {
        padding: 1rem;
        h2 {
            font-size: 2rem;
        }

        h3 {
            font-size: 0.8rem;
        }

        p {
            width: 100%;
            font-size: 0.8rem;
            overflow-wrap: break-word;
        }

        div {
            max-width: 100%;
            padding: 15px;
        }
    }

    .gallery {
        padding: 10px;
        
        h2 {
            font-size: 1.8rem;
            margin-bottom: 15px;
        }

        div {
            grid-template-columns: 1fr;
            gap: 15px;
            padding: 10px;
        }

        img {
            height: 250px;
        }
    }

    article {
        margin: 10px;
        padding: 15px;

        h2 {
            font-size: 1.8rem;
            margin-bottom: 15px;
        }

        h3 {
            font-size: 1.2rem;
            margin-bottom: 8px;
        }

        p {
            font-size: 0.9rem;
        }

        form {
            gap: 8px;
        }

        input, textarea {
            padding: 8px;
            font-size: 14px;
        }

        button {
            padding: 8px 16px;
            font-size: 14px;
        }
    }
}

