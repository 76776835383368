//mixins
@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@mixin flexColumn {
  display: flex;
  flex-direction: column;
}

@mixin flexColumnCenter {
  @include flexColumn;
  @include flexCenter;
}

@mixin flexRow {
  display: flex;
  flex-direction: row;
}

@mixin flexRowCenter {
  @include flexRow;
  @include flexCenter;
}

@mixin wh100 {
  width: 100%;
  height: 100%;
}

@mixin grid1 {
  display: grid;
  grid-template-columns: 1fr;
}

@mixin grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@mixin grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

@mixin grid4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

//fonts
$mt: 'Montserrat', sans-serif;
$os: 'Open Sans', sans-serif;
$pop: 'Poppins', sans-serif;
//colors
$dark: rgb(9, 24, 53);
$dark-active: rgb(30, 84, 185);
$light: #fff;
$light-active: #dddddd;
//transitions
$e: all 0.2s ease;
$ei: all 0.2s ease-in;
$eo: all 0.2s ease-out;
$eio: all 0.3s ease;

